import React, {FC} from 'react';
import {VStack} from '@chakra-ui/react';
import Title from '../Title';
import ListOption from '../ListOption';
import {useCreateProgramContext} from '../CreateProgramModal';

export const getMethodologyName = (methodology: string | undefined) => {
  switch (methodology) {
    case 'bant':
      return 'BANT';
    case 'medpicc':
      return 'MEDPICC';
    case 'winning':
      return 'Winning by Design';
    case 'own':
      return 'We use our own';
    default:
      return '';
  }
};

const StepMethodology: FC = () => {
  const {state, dispatch} = useCreateProgramContext();
  return (
    <>
      <Title
        title="What methodology do you use?"
        subtitle="Select the option that closely aligns with your methodology."
        onBack={() => dispatch({type: 'goBack'})}
      />
      <VStack spacing={4} alignItems="flex-start" w="100%">
        <ListOption
          number={1}
          name={getMethodologyName('bant')}
          onClick={() => dispatch({type: 'setMethodology', payload: 'bant'})}
          isSelected={state.methodology === 'bant'}
        />
        <ListOption
          number={2}
          name={getMethodologyName('medpicc')}
          onClick={() => dispatch({type: 'setMethodology', payload: 'medpicc'})}
          isSelected={state.methodology === 'medpicc'}
        />
        <ListOption
          number={3}
          name={getMethodologyName('winning')}
          onClick={() => dispatch({type: 'setMethodology', payload: 'winning'})}
          isSelected={state.methodology === 'winning'}
        />
        <ListOption
          number={4}
          name={getMethodologyName('own')}
          onClick={() => dispatch({type: 'setMethodology', payload: 'own'})}
          isSelected={state.methodology === 'own'}
        />
      </VStack>
    </>
  );
};

export default StepMethodology;
