import React, {FC, useMemo} from 'react';
import {Button, HStack, VStack} from '@chakra-ui/react';
import {useCreateProgramContext} from './CreateProgramModal';

const Footer: FC = () => {
  const {state, dispatch, onClose, isManager} = useCreateProgramContext();
  const isNextStepDisabled = useMemo(() => {
    switch (state.step) {
      case 'people':
        return state.people.length === 0 && state.teams.length === 0 && isManager;
      case 'methodology':
        return !state.methodology;
      case 'problem':
        return !state.problem;
      case 'funnel':
        return state.funnel.length === 0;
      case 'skills':
        return state.skills.length === 0;
      case 'timeframe':
        return !state.timeframe;
      case 'enroll':
        return !state.enrolledSomeone;
    }
  }, [state, isManager]);

  return (
    <VStack
      w="100%"
      aria-label="footer"
      borderTopWidth={1}
      borderTopColor="gray.75"
      background="black"
    >
      <HStack w={state.step === 'enroll' ? 1000 : 600} justifyContent="flex-end" m={4}>
        <Button
          variant="outline"
          onClick={() =>
            state.enrolledSomeone && state.step === 'enroll'
              ? onClose()
              : dispatch({type: 'nextStep'})
          }
          isDisabled={isNextStepDisabled}
        >
          {state.step === 'enroll' ? 'Done' : 'Next Step'}
        </Button>
      </HStack>
    </VStack>
  );
};

export default Footer;
