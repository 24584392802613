import {FC, useMemo} from 'react';
import {Flex, Grid, Text} from '@chakra-ui/react';
import {useCreateProgramContext} from './CreateProgramModal';
import {getTimeframeName} from './steps/StepTimeframe';
import {useSession} from 'context/SessionContext';

interface Props {
  todo?: boolean;
}

const Parameters: FC<Props> = () => {
  const {state, allFunnelCategories, allPeople, allSkills, allTeams, isManager} =
    useCreateProgramContext();

  const session = useSession();
  const user = useMemo(() => {
    return session?.data?.user;
  }, [session]);

  const audience = useMemo<string>(() => {
    if (!user) return '';

    if (!isManager) return user.firstName + ' ' + user.lastName;

    const parts = [];
    if (state.teams.length === 1) {
      parts.push(allTeams.find((t) => t.id === state.teams[0])?.name);
    } else if (state.teams.length > 1) {
      parts.push(`${state.teams.length} teams`);
    }

    if (state.people.length === 1) {
      const person = allPeople.find((p) => p.id === state.people[0]);
      if (person) {
        parts.push(person.firstName + ' ' + person.lastName);
      }
    } else if (state.people.length > 1) {
      parts.push(`${state.people.length} people`);
    }

    if (parts.length === 2) return parts.join(' & ');
    else if (parts.length > 2) return parts.join(', ');
    else return parts.join('');
  }, [allPeople, allTeams, state.people, state.teams, isManager, user]);

  return (
    <Flex borderWidth={1} borderColor="gray.75" borderRadius={4} mb={4} w="100%">
      <Grid templateColumns="repeat(3, 1fr)" gap={4} p={4} w="100%">
        {audience ? (
          <Flex flexDirection="column">
            <Text fontSize="12px" lineHeight="16px" fontWeight="500" color="gray.50">
              Audience
            </Text>
            <Text fontSize="16px" lineHeight="28px" fontWeight="400" color="gray.25">
              {audience}
            </Text>
          </Flex>
        ) : null}
        <Flex flexDirection="column">
          <Text fontSize="12px" lineHeight="16px" fontWeight="500" color="gray.50">
            Timeframe
          </Text>
          <Text fontSize="16px" lineHeight="28px" fontWeight="400" color="gray.25">
            {getTimeframeName(state.timeframe)}
          </Text>
        </Flex>
        <Flex flexDirection="column">
          <Text fontSize="12px" lineHeight="16px" fontWeight="500" color="gray.50">
            Problem
          </Text>
          <Text fontSize="16px" lineHeight="28px" fontWeight="400" color="gray.25">
            {state.problem === 'funnel' ? 'Improve Funnel' : 'Improve Skills'}
          </Text>
        </Flex>
        {state.problem === 'funnel' && (
          <Flex flexDirection="column" gridColumn="span 2">
            <Text fontSize="12px" lineHeight="16px" fontWeight="500" color="gray.50">
              Funnel Stage
            </Text>
            <Text fontSize="16px" lineHeight="28px" fontWeight="400" color="gray.25">
              {state.funnel
                .map((id) => allFunnelCategories.find((f) => f.id === id)?.name)
                .join(', ')}
            </Text>
          </Flex>
        )}
        {state.problem === 'skills' && (
          <Flex flexDirection="column" gridColumn="span 2">
            <Text fontSize="12px" lineHeight="16px" fontWeight="500" color="gray.50">
              Skills
            </Text>
            <Text fontSize="16px" lineHeight="28px" fontWeight="400" color="gray.25">
              {state.skills.map((id) => allSkills.find((s) => s.id === id)?.name).join(', ')}
            </Text>
          </Flex>
        )}
      </Grid>
    </Flex>
  );
};

export default Parameters;
