import React, {
  createContext,
  Dispatch,
  FC,
  Reducer,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef
} from 'react';
import {Flex, Modal, ModalContent} from '@chakra-ui/react';
import {ModalCloseButton} from '@chakra-ui/modal';
import StepPeople from './steps/StepPeople';
import StepMethodology from './steps/StepMethodology';
import StepProblem from './steps/StepProblem';
import Header from './Header';
import Footer from './Footer';
import StepFunnel from './steps/StepFunnel';
import StepSkills from './steps/StepSkills';
import StepTimeframe from './steps/StepTimeframe';
import StepEnroll from './steps/StepEnroll';
import {
  CourseWaitlistDesiredUrgencyTypeEnum,
  Tags,
  useGetFunnelCategoryTagsQuery,
  useGetManagerTeamsQuery,
  useGetSkillTagsQuery
} from 'generated/graphql';
import useIsManager from 'hooks/useIsManager';
import {useSession} from 'context/SessionContext';
import _ from 'lodash';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

type Step = 'people' | 'methodology' | 'problem' | 'funnel' | 'skills' | 'timeframe' | 'enroll';
type Methodology = 'bant' | 'medpicc' | 'winning' | 'own';
type Problem = 'funnel' | 'skills';

type FlowState = {
  step: Step;
  teams: string[];
  people: string[];
  methodology?: Methodology;
  problem?: Problem;
  funnel: string[];
  skills: string[];
  timeframe?: CourseWaitlistDesiredUrgencyTypeEnum;
  enrolledSomeone: boolean;
};

const initialState: FlowState = {
  step: 'people',
  teams: [],
  people: [],
  funnel: [],
  skills: [],
  enrolledSomeone: false
};

type UUID = string;

export type Action =
  | {type: 'nextStep'}
  | {type: 'goBack'}
  | {type: 'addTeam'; payload: UUID}
  | {type: 'removeTeam'; payload: UUID}
  | {type: 'addPerson'; payload: UUID}
  | {type: 'removePerson'; payload: UUID}
  | {type: 'setMethodology'; payload: Methodology}
  | {type: 'setProblem'; payload: Problem}
  | {type: 'setStep'; payload: Step}
  | {type: 'toggleFunnel'; payload: string}
  | {type: 'toggleSkill'; payload: string}
  | {type: 'setTimeframe'; payload: CourseWaitlistDesiredUrgencyTypeEnum}
  | {type: 'enrolledSomeone'}
  | {type: 'reset'};

const reducer: Reducer<FlowState, Action> = (state, action) => {
  const {step} = state;
  switch (action.type) {
    case 'nextStep':
      if (step === 'people') {
        return {...state, step: 'problem'};
        // } else if (step === 'methodology') {
        //   return {...state, step: 'problem'};
      } else if (step === 'problem' && state.problem) {
        return {...state, step: state.problem};
      } else if (step === 'funnel') {
        return {...state, step: 'timeframe', skills: []};
      } else if (step === 'skills') {
        return {...state, step: 'timeframe', funnel: []};
      } else if (step === 'timeframe') {
        return {...state, step: 'enroll'};
      }
      break;
    case 'goBack':
      /*if (step == 'methodology') {
        return {...state, step: 'people'};
      } else*/ if (step === 'problem') {
        return {...state, step: 'people'};
      } else if (step === 'funnel' || step === 'skills') {
        return {...state, step: 'problem'};
      } else if (step === 'timeframe') {
        return {...state, step: state.funnel.length > 0 ? 'funnel' : 'skills'};
      } else if (step === 'enroll') {
        return {...state, step: 'timeframe'};
      }
      break;
    case 'setStep':
      return {...state, step: action.payload};
    case 'addPerson':
      const newPeople = [...state.people, action.payload];
      return {...state, people: _.uniq(newPeople)};
    case 'removePerson':
      return {...state, people: state.people.filter((p) => p !== action.payload)};
    case 'addTeam':
      return {...state, teams: [...state.teams, action.payload]};
    case 'removeTeam':
      return {...state, teams: state.teams.filter((t) => t !== action.payload)};
    case 'setMethodology':
      return {...state, methodology: action.payload};
    case 'setProblem':
      return {...state, problem: action.payload};
    case 'toggleFunnel':
      if (state.funnel.includes(action.payload)) {
        return {...state, funnel: state.funnel.filter((f) => f !== action.payload)};
      } else if (state.funnel.length < 3) {
        return {...state, funnel: [...state.funnel, action.payload]};
      } else {
        return state;
      }
    case 'toggleSkill':
      if (state.skills.includes(action.payload)) {
        return {...state, skills: state.skills.filter((s) => s !== action.payload)};
      } else if (state.skills.length < 5) {
        return {...state, skills: [...state.skills, action.payload]};
      } else {
        return state;
      }
    case 'setTimeframe':
      return {...state, timeframe: action.payload};
    case 'enrolledSomeone':
      return {...state, enrolledSomeone: true};
    case 'reset':
      return initialState;
    default:
      return state;
  }
  return state;
};

type Team = {id: string; name: string};
type Person = {id: string; firstName: string; lastName: string; teamIds: string[]};

type ContextType = {
  state: FlowState;
  dispatch: Dispatch<Action>;
  allTeams: Team[];
  allPeople: Person[];
  allSkills: Tags[];
  allFunnelCategories: Tags[];
  onClose: () => void;
  isManager: boolean;
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const CreateProgramContext = createContext<ContextType>();

const CreateProgramModal: FC<Props> = ({isOpen, onClose: _onClose}) => {
  const initialRef = useRef(null);
  const isManager = useIsManager();

  const [state, dispatch] = useReducer(reducer, initialState);
  const step = useMemo(() => state.step, [state.step]);
  const session = useSession();

  const onClose = useCallback(() => {
    dispatch({type: 'reset'});
    _onClose();
  }, [_onClose]);

  useEffect(() => {
    if (isOpen) {
      const banner = document.getElementsByClassName('notification-bar-container');
      if (banner.length > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        banner[0].style.display = 'none';
      }
    }
  }, [isOpen]);

  useEffect(() => {
    const userId = session.data?.user?.id;
    if (!isManager && userId) {
      dispatch({
        type: 'addPerson',
        payload: userId
      });
    }
  }, [isManager, session.data?.user?.id, dispatch]);

  useEffect(() => {
    if (!isOpen) return;
    const step = isManager ? 'people' : 'problem';
    dispatch({
      type: 'setStep',
      payload: step
    });
  }, [isManager, isOpen]);

  const content = useMemo(() => {
    switch (step) {
      case 'people':
        return <StepPeople focusRef={initialRef} />;
      case 'methodology':
        return <StepMethodology />;
      case 'problem':
        return <StepProblem />;
      case 'funnel':
        return <StepFunnel />;
      case 'skills':
        return <StepSkills />;
      case 'timeframe':
        return <StepTimeframe />;
      case 'enroll':
        return <StepEnroll />;
      default:
        return null;
    }
  }, [step]);

  const layoutWidth = useMemo(() => {
    switch (state.step) {
      case 'enroll':
      case 'funnel':
        return '100%';
      default:
        return 600;
    }
  }, [state.step]);

  const managerTeamsQuery = useGetManagerTeamsQuery({skip: !isManager});

  const allTeams = useMemo<Team[]>(() => {
    return (
      managerTeamsQuery.data?.subordinates?.reduce<Team[]>((acc, sub) => {
        const teams = [...acc];
        sub.teamIds?.forEach((tuple) => {
          if (!teams.find((t) => t.id === tuple[0])) {
            teams.push({id: tuple[0], name: tuple[1]});
          }
        });
        return teams;
      }, []) ?? []
    );
  }, [managerTeamsQuery.data?.subordinates]);

  const allPeople = useMemo<Person[]>(() => {
    return (
      managerTeamsQuery.data?.subordinates?.map<Person>((sub) => ({
        id: sub.userId,
        firstName: sub.firstName ?? '',
        lastName: sub.lastName ?? '',
        teamIds: sub.teamIds?.map((t) => t[0]) ?? []
      })) ?? []
    );
  }, [managerTeamsQuery.data?.subordinates]);

  const skillsQuery = useGetSkillTagsQuery();
  const allSkills = useMemo(
    () => (skillsQuery.data?.tags as Tags[]) ?? [],
    [skillsQuery.data?.tags]
  );

  const funnelCategoryQuery = useGetFunnelCategoryTagsQuery();
  const allFunnelCategories = useMemo(
    () => (funnelCategoryQuery.data?.tags as Tags[]) ?? [],
    [funnelCategoryQuery.data?.tags]
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="full"
      initialFocusRef={initialRef}
      scrollBehavior="inside"
    >
      <ModalContent
        background="url(/images/backgrounds/program-modal.svg)" // todo: fix this to use primitives
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="0 0"
        alignItems="center"
      >
        <CreateProgramContext.Provider
          value={{
            state,
            dispatch,
            allTeams,
            allPeople,
            allSkills,
            allFunnelCategories,
            onClose,
            isManager
          }}
        >
          <ModalCloseButton />
          <Header />
          <Flex
            flexDirection="column"
            w="100%"
            alignItems="center"
            flexGrow="1"
            overflow="auto"
            aria-label="content"
          >
            <Flex flexDirection="column" w={layoutWidth} flexGrow="1" pt={12}>
              {content}
            </Flex>
          </Flex>
          <Footer />
        </CreateProgramContext.Provider>
      </ModalContent>
    </Modal>
  );
};

export default CreateProgramModal;

export function useCreateProgramContext(): ContextType {
  return useContext(CreateProgramContext);
}
