import React, {FC} from 'react';
import {VStack, Text, Divider} from '@chakra-ui/react';
import Title from '../Title';
import ListOption from '../ListOption';
import {faBookOpen, faFilter} from '@fortawesome/pro-regular-svg-icons';
import {useCreateProgramContext} from '../CreateProgramModal';

const StepProblem: FC = () => {
  const {state, dispatch, isManager} = useCreateProgramContext();
  return (
    <>
      {isManager ? (
        <Title
          title="What problem are you trying to solve for?"
          subtitle="Select one."
          onBack={() => dispatch({type: 'goBack'})}
        />
      ) : (
        <>
          <Title
            title="Find your session"
            subtitle="Answer a few questions to match with the right session."
          />
          <Divider orientation="horizontal" mb={8} bg="lime.300" />
          <Text fontSize="24px" lineHeight="36px" fontWeight="600" mb={2}>
            What problem are you trying to solve for?
          </Text>
          <Text fontSize="16px" fontWeight="600" color="gray.30" mb={8}>
            Select one.
          </Text>
        </>
      )}
      <VStack spacing={4} alignItems="flex-start" w="100%">
        <ListOption
          icon={faFilter}
          name="Need to improve part of our funnel"
          onClick={() => dispatch({type: 'setProblem', payload: 'funnel'})}
          isSelected={state.problem === 'funnel'}
        />
        <ListOption
          icon={faBookOpen}
          name="Need to improve specific skills"
          onClick={() => dispatch({type: 'setProblem', payload: 'skills'})}
          isSelected={state.problem === 'skills'}
        />
      </VStack>
    </>
  );
};

export default StepProblem;
