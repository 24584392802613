import React, {FC} from 'react';
import {Grid} from '@chakra-ui/react';
import Title from '../Title';
import {useCreateProgramContext} from '../CreateProgramModal';
import GridOption from '../GridOption';

const StepSkills: FC = () => {
  const {state, dispatch, allSkills} = useCreateProgramContext();
  return (
    <>
      <Title
        title="Which skills do you want to improve?"
        subtitle="Select up to 5."
        onBack={() => dispatch({type: 'goBack'})}
      />
      <Grid templateColumns="repeat(3, 1fr)" gridRowGap="16px" w="100%" gridColumnGap="27px">
        {allSkills.map((skill) => (
          <GridOption
            key={skill.id}
            name={skill.name}
            description={skill.description}
            onClick={() => dispatch({type: 'toggleSkill', payload: skill.id})}
            isSelected={state.skills.includes(skill.id)}
          />
        ))}
      </Grid>
    </>
  );
};

export default StepSkills;
