import React, {FC} from 'react';
import {Box, Flex, Text} from '@chakra-ui/react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck} from '@fortawesome/pro-regular-svg-icons';

interface Props {
  name: string;
  description?: string;
  onClick: () => void;
  isSelected?: boolean;
}

const GridOption: FC<Props> = ({name, description, onClick, isSelected}) => {
  return (
    <Flex
      cursor="pointer"
      onClick={onClick}
      w={182}
      borderWidth={1}
      borderColor={isSelected ? 'lime.200' : 'gray.100'}
      borderRadius={8}
      p={6}
      background={isSelected ? 'rgba(226, 254, 98, 0.10)' : 'gray.200'}
      position="relative"
      overflow="hidden"
      _hover={{
        borderColor: 'lime.200'
      }}
    >
      <Box
        position="absolute"
        left={-215}
        top={-185}
        w={546.641}
        h={800.939}
        borderRadius={800.939}
        background="radial-gradient(37.11% 37.11% at 50% 50%, rgba(26, 192, 153, 0.08) 0%, rgba(0, 6, 13, 0.00) 100%)"
      />
      <Box
        position="absolute"
        left={-16}
        top={-320}
        w={546.641}
        h={800.939}
        borderRadius={800.939}
        background="radial-gradient(37.11% 37.11% at 50% 50%, rgba(21, 39, 241, 0.35) 0%, rgba(0, 6, 13, 0.00) 100%)"
      />

      <Flex flexDirection="column" gap="18px">
        <Flex gap={2}>
          <Text fontWeight="600" fontSize="14px" lineHeight="16px">
            {name}
          </Text>
          <Flex
            justifyContent="center"
            alignItems="center"
            borderRadius={24}
            w="20px"
            h="20px"
            minWidth="20px"
            background="lime.200"
            opacity={isSelected ? 1 : 0}
          >
            <FontAwesomeIcon icon={faCheck} size="sm" color="black" />
          </Flex>
        </Flex>
        {description && (
          <Text fontWeight="400" fontSize="12px" lineHeight="16px">
            {description}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default GridOption;
