import React, {FC, useCallback, useMemo, useState} from 'react';
import {
  Box,
  Button,
  Center,
  Circle,
  Divider,
  Flex,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Skeleton,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import {useCreateProgramContext} from '../CreateProgramModal';
import {
  CourseEnrollmentStatusEnum,
  CourseTypeEnum,
  CourseWaitlistDesiredUrgencyTypeEnum,
  Scalars,
  useAddUserToCourseWaitlistMutation,
  useCreateProgramMutation,
  useGetProgramMatchesQuery
} from 'generated/graphql';
import moment from 'moment';
import {buildDateStringWithTimeFromStartDateAndStartTime, formatDate} from 'utils/dates/formatDate';
import {FUTURE_SESSION_DATE, TIME_FORMAT} from 'utils/constants';
import {EnrollButton} from '../../../../Buttons';
import {EnrollmentContextProvider} from '../../../../../context/EnrollmentContext';
import ConfettiExplosion from 'react-confetti-explosion';
import TagDisplay from '../../../../Tags/TagDisplay';
import MyTeamSelectionModal from '../../../../Modals/MyTeamSelectionModal';
import colors from 'ui/colors';
import {useSession} from '../../../../../context/SessionContext';
import Parameters from '../Parameters';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/pro-regular-svg-icons';
import useIsManager from 'hooks/useIsManager';
import {useSignInModal} from 'context/SignInModalContext';
import {faX} from '@fortawesome/pro-thin-svg-icons';
import {coursePath} from 'utils/routeFactory';
import Link from 'next/link';

const StepEnroll: FC = () => {
  const session = useSession();
  const userId = session.data?.user?.id;
  const organizationId = session.data?.user?.selectedOrg?.id;
  const {state, dispatch, allPeople, isManager} = useCreateProgramContext();
  const [showConfetti, setShowConfetti] = useState(false);
  const [isExploding, setIsExploding] = useState(false);
  const [coursesActioned, setCoursesActioned] = useState<string[]>([]);
  const actionCourse = useCallback((courseId: string) => {
    setCoursesActioned((prev) => [...prev, courseId]);
  }, []);

  const selectedMembers = useMemo(() => {
    const s = new Set<string>();
    state.people.forEach((p) => s.add(p));

    state.teams.forEach((teamId) => {
      allPeople.forEach((p) => {
        if (p.teamIds.includes(teamId)) {
          s.add(p.id);
        }
      });
    });

    return Array.from(s);
  }, [state.people, state.teams, allPeople]);

  const [timeframe] = useState(
    moment
      .utc()
      .add(
        ...(state.timeframe === CourseWaitlistDesiredUrgencyTypeEnum.NEXT_3_WEEKS
          ? [3, 'weeks']
          : state.timeframe === CourseWaitlistDesiredUrgencyTypeEnum.NEXT_6_WEEKS
          ? [6, 'weeks']
          : [3, 'months'])
      )
      .format()
  );

  const onCloseTeamModal = useCallback(() => {
    if (showConfetti) {
      setShowConfetti(false);
      setIsExploding(true);
    }
  }, [showConfetti]);

  const onEnrollmentChange = useCallback(() => {
    setShowConfetti(true);
    dispatch({type: 'enrolledSomeone'});
  }, [dispatch]);

  const onExplosionComplete = useCallback(() => setIsExploding(false), []);

  const tagIds = useMemo(() => {
    if (state.problem === 'skills') {
      return state.skills;
    } else if (state.problem === 'funnel') {
      return state.funnel;
    }
    return [];
  }, [state.problem, state.skills, state.funnel]);

  const coursesQuery = useGetProgramMatchesQuery({
    variables: {timeframe, tagIds},
    skip: tagIds.length === 0
  });

  const getStartDate = useCallback((startDate: string, startTime: string) => {
    const dateString = buildDateStringWithTimeFromStartDateAndStartTime(startDate, startTime);

    return (
      <>
        <Text color="textColor.100" fontSize="14px">
          {moment.utc(dateString).local().format(FUTURE_SESSION_DATE)}
        </Text>
        <Text color="textColor.100" fontSize="14px">
          {formatDate(new Date(dateString), TIME_FORMAT)}
        </Text>
      </>
    );
  }, []);

  const [createProgramMutation] = useCreateProgramMutation();

  const captureProgramInstance = useCallback(
    (
      action: string,
      userIds: string[],
      courseId: string,
      courseIds: string[],
      cohortId?: string
    ) => {
      createProgramMutation({
        variables: {
          input: {
            createdById: userId,
            methodology: state.methodology,
            timeframe: state.timeframe,
            selectedUsers: {data: selectedMembers.map((userId) => ({userId}))},
            tags: {data: tagIds.map((tagId) => ({tagId}))},
            actionsTaken: {
              data: userIds.map((userId) => ({
                action,
                cohortId,
                courseId,
                organizationId,
                userId
              }))
            },
            coursesShown: {
              data: courseIds.map((courseId) => ({
                courseId
              }))
            }
          }
        }
      }).catch(console.error);
    },
    [
      createProgramMutation,
      organizationId,
      selectedMembers,
      state.methodology,
      state.timeframe,
      tagIds,
      userId
    ]
  );

  const tableRows = useMemo(() => {
    if (coursesQuery.loading || !coursesQuery.data?.courses) {
      return Array(10)
        .fill(0)
        .map((_, i) => (
          <Flex key={`loading${i}`} flexDirection="column" width="100%" pt={3} pb={3}>
            <Flex flexDirection="row" width="100%" gap={3}>
              <Skeleton height="2.5rem" flexGrow={1} />
              <Skeleton height="2.5rem" width={130} />
              <Skeleton height="2.5rem" width={175} />
            </Flex>
          </Flex>
        ));
    }
    const courses = [
      ...[...coursesQuery.data.courses]
        .filter((c) => c.cohorts.length > 0)
        .sort(
          (c1, c2) =>
            new Date(c1.cohorts[0].startDate).getTime() -
            new Date(c2.cohorts[0].startDate).getTime()
        ),
      ...coursesQuery.data.courses.filter((c) => c.cohorts.length === 0)
    ];

    const rows: JSX.Element[] = [];
    courses.forEach((course) => {
      const actions =
        course.cohorts.length > 0 ? (
          course.cohorts.map((cohort, index) => (
            <>
              <Flex key={`cohort-${cohort.id}`} flexDirection="row" pb={3}>
                <Text minWidth={130} maxWidth={130} fontSize="14px">
                  {getStartDate(cohort.startDate, cohort.classTimeOfDay)}
                </Text>
                {userId ? (
                  <EnrollmentContextProvider
                    courseName={course.name ?? ''}
                    cohortId={cohort?.id ?? ''}
                    cohortSlug={cohort?.slug ?? ''}
                    cohortStartTime={cohort?.classEvents?.[0]?.startTime}
                    openForEnrollment={course.enrollmentStatus === CourseEnrollmentStatusEnum.OPEN}
                  >
                    <EnrollButton
                      cohortId={cohort?.id}
                      courseType={CourseTypeEnum.LIGHTNING_SESSION}
                      variant="outline"
                      size="sm"
                      onEnrollmentChange={(action, userIds) => {
                        if (action === 'enroll') {
                          captureProgramInstance(
                            'enroll',
                            userIds,
                            course.id,
                            courses.map((c) => c.id),
                            cohort.id
                          );
                        }
                        onEnrollmentChange();
                        actionCourse(course.id);
                      }}
                      onCloseTeamEnrollModal={onCloseTeamModal}
                      selectedMembers={selectedMembers}
                      closeOnEnroll
                    />
                  </EnrollmentContextProvider>
                ) : (
                  <Link href={coursePath({asUrl: true}).courseDetails(course?.slug)} passHref>
                    <Button variant="outline" size="sm" cursor="pointer">
                      Learn More
                    </Button>
                  </Link>
                )}
              </Flex>
              {index < course.cohorts.length - 1 && <Divider mb={3} />}
            </>
          ))
        ) : (
          <Flex flexDirection="row" pb={3}>
            <Text minWidth={130} maxWidth={130} fontSize="14px">
              {' '}
            </Text>
            {userId ? (
              <RequestSessionButton
                onChange={(userIds) => {
                  captureProgramInstance(
                    'request',
                    userIds,
                    course.id,
                    courses.map((c) => c.id)
                  );
                  onEnrollmentChange();
                  actionCourse(course.id);
                }}
                onClose={onCloseTeamModal}
                courseSlug={course.slug ?? ''}
                desiredUrgency={
                  state.timeframe ?? CourseWaitlistDesiredUrgencyTypeEnum.NEXT_3_MONTHS
                }
                desiredTimezone="US"
                selectedMembers={selectedMembers}
              />
            ) : (
              <Link href={coursePath({asUrl: true}).courseDetails(course?.slug)} passHref>
                <Button variant="outline" size="sm" cursor="pointer">
                  Learn More
                </Button>
              </Link>
            )}
          </Flex>
        );

      rows.push(
        <Flex
          key={course.id}
          flexDirection="row"
          width="100%"
          pt={4}
          pb={4}
          borderBottomWidth={1}
          borderColor="gray.75"
          gap={3}
        >
          <Flex flexDirection="column" flexGrow={1} alignItems="flex-start">
            <Flex flexDirection="row" alignItems="center" pb={2} gap={1}>
              {course.tags
                .map((tag) => tag.tag)
                .filter((t) => t?.tagType?.slug === 'role')
                .map((tag) => (
                  <TagDisplay
                    variant="default"
                    key={tag?.id}
                    name={tag?.name ?? ''}
                    slug={tag?.slug ?? ''}
                    tagTypeSlug={tag?.tagType?.slug}
                  />
                ))}
              {course.tags
                .map((tag) => tag.tag)
                .filter((t) => t?.tagType?.slug === 'funnel-category')
                .map((tag) => (
                  <>
                    <Text ml={1} mr={1} p={0}>
                      •
                    </Text>
                    <TagDisplay
                      variant="default"
                      key={tag?.id}
                      name={tag?.name ?? ''}
                      slug={tag?.slug ?? ''}
                      tagTypeSlug={tag?.tagType?.slug}
                    />
                  </>
                ))}
            </Flex>
            <Text fontWeight="700" fontSize="36px" lineHeight="40px" mr={4}>
              {course.name}
            </Text>
            <HStack wrap="wrap" gap={2} pt={3} pb={3}>
              {course.tags
                .map((tag) => tag.tag)
                .filter((t) => t?.tagType?.slug === 'skill')
                .map((tag) => (
                  <TagDisplay
                    variant="default"
                    key={tag?.id}
                    name={tag?.name ?? ''}
                    slug={tag?.slug ?? ''}
                    tagTypeSlug={tag?.tagType?.slug}
                  />
                ))}
            </HStack>
            <Box
              pt={2}
              pb={2}
              flexGrow={1}
              dangerouslySetInnerHTML={{__html: course.shortDescription ?? ''}}
            />
          </Flex>
          <Flex flexDirection="column" alignItems="flex-end">
            {actions}
            {coursesActioned.includes(course.id) && (
              <Text
                display="flex"
                color="lime.200"
                fontSize="16px"
                height="100%"
                alignItems="flex-end"
              >
                {course.cohorts.length > 0 ? 'Enrolled!' : 'Requested!'}
              </Text>
            )}
          </Flex>
        </Flex>
      );
    });
    return rows;
  }, [
    userId,
    coursesQuery.data?.courses,
    coursesQuery.loading,
    getStartDate,
    onCloseTeamModal,
    onEnrollmentChange,
    selectedMembers,
    state.timeframe,
    captureProgramInstance,
    coursesActioned,
    actionCourse
  ]);

  return (
    <Flex flexDirection="column" alignItems="center">
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        w={1000}
        left={0}
        pb={20}
        position="relative"
      >
        <IconButton
          left="-56px"
          position="absolute"
          aria-label="go back"
          onClick={() => dispatch({type: 'goBack'})}
          variant="unstyled"
          color="lime.200"
          height="52px"
          icon={<FontAwesomeIcon icon={faArrowLeft} size="2x" />}
        />
        <Text fontSize="36px" lineHeight="36px" fontWeight="600" mb={3}>
          {state.enrolledSomeone ? 'Well done!' : 'We recommend these sessions'}
        </Text>
        <Text fontSize="14px" lineHeight="14px" fontWeight="400" mb={10}>
          {isManager ? (
            <>
              {state.enrolledSomeone
                ? "Don't stop there – empower your team by enrolling them in more sessions"
                : 'Enroll your team to start making an impact'}
            </>
          ) : (
            <>
              {state.enrolledSomeone
                ? "Don't stop there - enroll in more sessions and level up your skills"
                : 'Enroll in session to start making an impact'}
            </>
          )}
        </Text>
        <Parameters />
        <Flex flexDirection="column" width="100%">
          {tableRows}
        </Flex>
      </Flex>
      {isExploding && (
        <Center position="fixed" top="50%" left="50%" transform="translate(-50%, -50%);">
          <ConfettiExplosion
            duration={3000}
            onComplete={onExplosionComplete}
            force={0.8}
            particleCount={250}
            width={2000}
            zIndex={1450}
            colors={colors.confetti}
          />
        </Center>
      )}
    </Flex>
  );
};

export default StepEnroll;

const RequestSessionButton: FC<{
  onChange: (userIds: string[]) => void;
  onClose: () => void;
  courseSlug: string;
  desiredUrgency: CourseWaitlistDesiredUrgencyTypeEnum;
  desiredTimezone: 'US';
  selectedMembers: Scalars['uuid'];
}> = ({onChange, onClose, courseSlug, desiredUrgency, desiredTimezone, selectedMembers}) => {
  const isManager = useIsManager();
  const session = useSession();
  const {openSignInModal} = useSignInModal();
  const [error, setError] = useState<string | undefined>();

  const failModalDisclosure = useDisclosure();

  const user = useMemo(() => {
    return session.data?.user;
  }, [session]);

  const [addUserToWaitlist, {loading: addUserLoading}] = useAddUserToCourseWaitlistMutation();
  const {isOpen, onOpen, onClose: _onClose} = useDisclosure();
  const handleClose = useCallback(() => {
    _onClose();
    onClose();
  }, [_onClose, onClose]);

  const onRequestForMyself = useCallback(() => {
    if (!user) {
      openSignInModal();
      return;
    }

    addUserToWaitlist({
      variables: {
        input: {
          courseSlug,
          desiredUrgency,
          desiredTimezone
        }
      }
    })
      .then((result) => {
        if (result.data?.AddUserToCourseWaitlist?.success) {
          onChange([user.id]);
        }
        handleClose();
      })
      .catch((error) => {
        if (
          error.message.includes(
            'unique constraint "course_waitlist_course_id_user_id_is_not_deleted_key"'
          )
        ) {
          setError('You have already requested a session for this course');
        }
        failModalDisclosure.onOpen();
        setTimeout(() => {
          failModalDisclosure.onClose();
          setError('');
        }, 5000);
        console.error(error);
      });
  }, [
    user,
    addUserToWaitlist,
    handleClose,
    failModalDisclosure,
    openSignInModal,
    courseSlug,
    desiredTimezone,
    desiredUrgency,
    onChange
  ]);

  const onRequestForMyTeam = useCallback(
    (userIds: string[]) => {
      addUserToWaitlist({
        variables: {
          input: {
            userIds,
            courseSlug,
            desiredUrgency,
            desiredTimezone
          }
        }
      }).then((result) => {
        if (result.data?.AddUserToCourseWaitlist?.success) {
          onChange(userIds);
        }
        handleClose();
      });
    },
    [addUserToWaitlist, courseSlug, desiredTimezone, desiredUrgency, handleClose, onChange]
  );

  const handleRequestClick = useCallback(() => {
    if (isManager) {
      onOpen();
    } else {
      onRequestForMyself();
    }
  }, [isManager, onOpen, onRequestForMyself]);

  return (
    <>
      <Button
        variant="outline"
        size="sm"
        onClick={handleRequestClick}
        isLoading={!isManager && addUserLoading}
      >
        Request Session
      </Button>
      <MyTeamSelectionModal
        labels={{
          listTitle: 'Select learners for session'
        }}
        isOpen={isOpen}
        onClose={handleClose}
        onlyActiveMembers
        actionLabel={(userIds) =>
          `Request session for ${userIds.length} learner${userIds.length === 1 ? '' : 's'}`
        }
        isActionRunning={addUserLoading}
        selectedMembers={selectedMembers}
        onActionRun={onRequestForMyTeam}
      />
      <Modal isOpen={failModalDisclosure.isOpen} onClose={failModalDisclosure.onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing="2" padding="100px 0">
              <Circle size="80px" bg="berry" color="white" mb="12px">
                <FontAwesomeIcon icon={faX} fontSize="35px" />
              </Circle>
              <Text fontFamily="Inter" fontSize="24px" fontWeight="bold" textAlign="center">
                Something went wrong!
              </Text>
              <Text pb="15px" textAlign="center">
                {error ?? 'There was an error while processing your request. Please, try again.'}{' '}
              </Text>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
