import React, {FC} from 'react';
import {Box, Text, VStack} from '@chakra-ui/react';

const Header: FC = () => (
  <VStack w="100%" aria-label="header" alignItems="flex-start">
    <Text m={4} fontSize="32px" fontWeight="600" color="textColor.100">
      Live Coaching Recommendations
    </Text>
    <Box
      h={2}
      w="100%"
      background="linear-gradient(90deg, #1AC099 1.54%, rgba(26, 192, 153, 0.00) 52.3%)"
    />
  </VStack>
);

export default Header;
