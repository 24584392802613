import React, {FC, RefObject, useMemo, useState} from 'react';
import {
  Box,
  Divider,
  Fade,
  Flex,
  Input,
  Tag,
  TagCloseButton,
  TagLabel,
  Text
} from '@chakra-ui/react';
import Title from '../Title';
import {useCreateProgramContext} from '../CreateProgramModal';

interface Props {
  focusRef: RefObject<HTMLInputElement>;
}

const StepPeople: FC<Props> = ({focusRef}) => {
  const {state, dispatch, allTeams, allPeople} = useCreateProgramContext();
  const [showResults, setShowResults] = useState(false);
  const [q, setQ] = useState<string>('');

  const foundTeams = useMemo(
    () =>
      allTeams
        .filter((t) => !state.teams.includes(t.id))
        .filter((t) => t.name.toLowerCase().includes(q.toLowerCase()))
        .sort((t1, t2) => t1.name.localeCompare(t2.name)),
    [allTeams, q, state.teams]
  );

  const foundPeople = useMemo(
    () =>
      allPeople
        .filter((p) => !state.people.includes(p.id))
        .filter(
          (p) =>
            p.firstName.toLowerCase().includes(q.toLowerCase()) ||
            p.lastName.toLowerCase().includes(q.toLowerCase())
        ),
    [allPeople, q, state.people]
  );

  return (
    <>
      <Text fontSize="36px" lineHeight="36px" fontWeight="600" mb={8}>
        Find your session
      </Text>
      <Text fontSize="16px" lineHeight="19.36px" fontWeight="600" color="gray.30" mb={8}>
        Answer a few questions about your team’s needs to match learners to the right session
      </Text>
      <Divider orientation="horizontal" mb={8} bg="lime.300" />
      <Title title="Who would you like to enroll?" subtitle="Select at least one person or team." />
      <Input
        ref={focusRef}
        w="100%"
        border="0"
        borderBottomWidth={1}
        borderBottomStyle="solid"
        borderBottomColor="lime.200"
        borderRadius={0}
        variant="unstyled"
        p={2}
        color="textColor.100"
        size="lg"
        onFocus={() => setShowResults(true)}
        onBlur={() => setShowResults(false)}
        mb={6}
        value={q}
        placeholder="Search for a person or team..."
        onChange={(e) => setQ(e.target.value)}
      />
      <Fade
        in={showResults}
        style={{zIndex: 1}}
        unmountOnExit
        variants={{
          enter: {
            opacity: 1
          },
          exit: {
            opacity: 0
          }
        }}
      >
        <Flex
          position="absolute"
          aria-label="search-results"
          background="rgba(0,0,0,1)"
          maxHeight="300px"
          p={3}
          w={600}
          alignItems="flex-start"
          overflow="auto"
          zIndex="10000"
          flexDirection="column"
        >
          {foundTeams.length > 0 && (
            <>
              <Text
                textTransform="uppercase"
                fontSize="12px"
                fontWeight="600"
                color="textColor.100"
                letterSpacing="1.6px"
              >
                Team
              </Text>
              {foundTeams.map((t) => (
                <Text
                  key={t.id}
                  fontSize="16px"
                  fontWeight="400"
                  color="gray.30"
                  lineHeight="16px"
                  cursor="pointer"
                  width="100%"
                  pt={1}
                  pb={1}
                  _hover={{color: 'textColor.100'}}
                  onClick={() => dispatch({type: 'addTeam', payload: t.id})}
                >
                  {t.name}
                </Text>
              ))}
            </>
          )}
          {foundPeople.length > 0 && (
            <>
              {foundTeams.length > 0 && (
                <Box w="100%" pt={4} pb={4}>
                  <Box
                    borderTopWidth={1}
                    borderTopStyle="solid"
                    borderColor="gray.75"
                    w="100%"
                    h="1px"
                  />
                </Box>
              )}
              <Text
                textTransform="uppercase"
                fontSize="12px"
                fontWeight="600"
                color="textColor.100"
                letterSpacing="1.6px"
              >
                People
              </Text>
              {foundPeople.map((p) => (
                <Text
                  key={p.id}
                  fontSize="16px"
                  fontWeight="400"
                  color="gray.30"
                  lineHeight="16px"
                  cursor="pointer"
                  width="100%"
                  pt={1}
                  pb={1}
                  _hover={{color: 'textColor.100'}}
                  onClick={() => dispatch({type: 'addPerson', payload: p.id})}
                >
                  {p.firstName} {p.lastName}
                </Text>
              ))}
            </>
          )}
          {foundTeams.length === 0 && foundPeople.length === 0 && (
            <Text
              fontSize="16px"
              fontWeight="400"
              color="gray.30"
              lineHeight="16px"
              fontStyle="italic"
              pb={20}
            >
              No results
            </Text>
          )}
        </Flex>
      </Fade>
      <Fade
        in={!showResults}
        variants={{
          enter: {
            opacity: 1
          },
          exit: {
            opacity: 0.2
          }
        }}
        unmountOnExit={false}
      >
        <Flex w="100%" flexWrap="wrap" mb={8}>
          {state.teams
            .map((teamId) => allTeams.find((_t) => _t.id === teamId))
            .map(
              (t) =>
                t && (
                  <Tag key={t.id} size="lg" borderRadius="full" background="gray.700" mb={1} mr={1}>
                    <TagLabel color="textColor.100">{t.name}</TagLabel>
                    <TagCloseButton
                      color="textColor.100"
                      onClick={() => dispatch({type: 'removeTeam', payload: t.id})}
                    />
                  </Tag>
                )
            )}
          {state.people
            .map((userId) => allPeople.find((_p) => _p.id === userId))
            .map(
              (p) =>
                p && (
                  <Tag key={p.id} size="lg" borderRadius="full" background="gray.700" mb={1} mr={1}>
                    <TagLabel color="textColor.100">
                      {p.firstName} {p.lastName}
                    </TagLabel>
                    <TagCloseButton
                      color="textColor.100"
                      onClick={() => dispatch({type: 'removePerson', payload: p.id})}
                    />
                  </Tag>
                )
            )}
        </Flex>
      </Fade>
    </>
  );
};

export default StepPeople;
