import React, {FC} from 'react';
import {Box, IconButton, Text} from '@chakra-ui/react';
import {faArrowLeft} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

interface Props {
  title: string;
  subtitle: string;
  onBack?: () => void;
}

const Title: FC<Props> = ({title, subtitle, onBack}) => {
  return (
    <Box w={600}>
      <Box mb={2} overflow="visible" position="relative">
        {onBack && (
          <IconButton
            left="-56px"
            position="absolute"
            aria-label="go back"
            onClick={onBack}
            variant="unstyled"
            color="lime.200"
            height="52px"
            icon={<FontAwesomeIcon icon={faArrowLeft} size="2x" />}
          />
        )}
        <Text fontSize="30px" fontWeight="600">
          {title}
        </Text>
      </Box>
      <Text fontSize="20px" fontWeight="400" color="gray.300" lineHeight={1} mb={12}>
        {subtitle}
      </Text>
    </Box>
  );
};

export default Title;
