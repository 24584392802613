import React, {FC, useMemo, useState} from 'react';
import {Box, Center, Flex, Text} from '@chakra-ui/react';
import Title from '../Title';
import {useCreateProgramContext} from '../CreateProgramModal';
import {Tags} from 'generated/graphql';

const StepFunnel: FC = () => {
  const {state, dispatch, allFunnelCategories} = useCreateProgramContext();

  type Slug = string;
  const tagLookup = useMemo(
    () =>
      allFunnelCategories.reduce((memo, tag) => {
        memo[tag.slug] = tag;
        return memo;
      }, {} as Record<Slug, Tags>),
    [allFunnelCategories]
  );

  return (
    <>
      <Center>
        <Title
          title="Which part of the sales funnel?"
          subtitle="Select up to 3."
          onBack={() => dispatch({type: 'goBack'})}
        />
      </Center>

      <Box>
        <Background />
        <Flex h={377} overflow="visible" justifyContent="center" alignItems="center">
          <Flex flexDirection="row" position="relative" alignItems="center" h="100%">
            <Tile
              name={tagLookup['funnel-category-awareness'].name}
              description={tagLookup['funnel-category-awareness'].description}
              tileHeight={328}
              outline={{
                h: 339,
                path: 'M188 302.969C106.967 311.026 43.5639 321.221 5 332.755V5.9209C43.5639 17.455 106.967 27.6505 188 35.7078V302.969Z'
              }}
              clipPath={
                state.funnel.includes(tagLookup['funnel-category-awareness'].id)
                  ? 'M183 297.969C101.967 306.026 38.5639 316.221 0 327.755V0.920898C38.5639 12.455 101.967 22.6505 183 30.7078V297.969Z'
                  : undefined
              }
              onClick={() =>
                dispatch({type: 'toggleFunnel', payload: tagLookup['funnel-category-awareness'].id})
              }
            />
            <Tile
              name={tagLookup['funnel-category-interest'].name}
              description={tagLookup['funnel-category-interest'].description}
              tileHeight={267.261}
              borderHeight={266}
              outline={{
                h: 279,
                path: 'M188 259.962C120.613 263.194 58.9055 267.608 5 272.968V5.70703C58.9055 11.0669 120.613 15.4806 188 18.7125V259.962Z'
              }}
              clipPath={
                state.funnel.includes(tagLookup['funnel-category-interest'].id)
                  ? 'M183 254.962C115.613 258.194 53.9055 262.608 0 267.968V0.707031C53.9055 6.06694 115.613 10.4806 183 13.7125V254.962Z'
                  : undefined
              }
              onClick={() =>
                dispatch({type: 'toggleFunnel', payload: tagLookup['funnel-category-interest'].id})
              }
            />
            <Tile
              name={tagLookup['funnel-category-evaluation'].name}
              description={tagLookup['funnel-category-evaluation'].description}
              tileHeight={241.25}
              borderHeight={240}
              outline={{
                h: 253,
                path: 'M188 241.425C123.761 242.319 62.3252 244.214 5 246.963V5.71289C62.3252 8.46227 123.761 10.3565 188 11.2506V241.425Z'
              }}
              clipPath={
                state.funnel.includes(tagLookup['funnel-category-evaluation'].id)
                  ? 'M183 236.425C118.761 237.319 57.3252 239.214 0 241.963V0.712891C57.3252 3.46227 118.761 5.3565 183 6.25063V236.425Z'
                  : undefined
              }
              onClick={() =>
                dispatch({
                  type: 'toggleFunnel',
                  payload: tagLookup['funnel-category-evaluation'].id
                })
              }
            />
            <Tile
              name={tagLookup['funnel-category-purchase'].name}
              description={tagLookup['funnel-category-purchase'].description}
              tileHeight={230.533}
              borderHeight={228}
              outline={{
                h: 241,
                path: 'M188 235.604C156.323 235.099 123.937 234.838 91 234.838C61.8661 234.838 33.1627 235.042 5 235.439V5.23643C33.1627 5.63315 61.8661 5.83763 91 5.83763C123.937 5.83763 156.323 5.57629 188 5.07129V235.604Z'
              }}
              clipPath={
                state.funnel.includes(tagLookup['funnel-category-purchase'].id)
                  ? 'M183 230.604C151.323 230.099 118.937 229.838 86 229.838C56.8661 229.838 28.1627 230.042 0 230.439V0.236434C28.1627 0.633155 56.8661 0.83763 86 0.83763C118.937 0.83763 151.323 0.576293 183 0.0712891V230.604Z'
                  : undefined
              }
              onClick={() =>
                dispatch({type: 'toggleFunnel', payload: tagLookup['funnel-category-purchase'].id})
              }
            />
            <Tile
              name={tagLookup['funnel-category-onboarding'].name}
              description={tagLookup['funnel-category-onboarding'].description}
              tileHeight={242.432}
              borderHeight={230}
              outline={{
                h: 253,
                path: 'M188 247.554C130.821 244.663 69.3756 242.631 5 241.604V11.0717C69.3756 10.0454 130.821 8.01276 188 5.12207V247.554Z'
              }}
              clipPath={
                state.funnel.includes(tagLookup['funnel-category-onboarding'].id)
                  ? 'M183 242.554C125.821 239.663 64.3756 237.631 0 236.604V6.0717C64.3756 5.0454 125.821 3.01276 183 0.12207V242.554Z'
                  : undefined
              }
              onClick={() =>
                dispatch({
                  type: 'toggleFunnel',
                  payload: tagLookup['funnel-category-onboarding'].id
                })
              }
            />
            <Tile
              name={tagLookup['funnel-category-growth-expansion'].name}
              description={tagLookup['funnel-category-growth-expansion'].description}
              tileHeight={269.705}
              borderHeight={241}
              outline={{
                h: 281,
                path: 'M188 275.191C134.467 269.606 72.7266 264.978 5 261.554V19.1221C72.7266 15.6982 134.467 11.0704 188 5.48535V275.191Z'
              }}
              clipPath={
                state.funnel.includes(tagLookup['funnel-category-growth-expansion'].id)
                  ? 'M183 270.191C129.467 264.606 67.7266 259.978 0 256.554V14.1221C67.7266 10.6982 129.467 6.07043 183 0.485352V270.191Z'
                  : undefined
              }
              onClick={() =>
                dispatch({
                  type: 'toggleFunnel',
                  payload: tagLookup['funnel-category-growth-expansion'].id
                })
              }
            />
            <Tile
              name={tagLookup['funnel-category-retention'].name}
              description={tagLookup['funnel-category-retention'].description}
              tileHeight={334.675}
              borderHeight={271}
              outline={{
                h: 347,
                path: 'M188 340.675C153.044 328.119 89.1664 316.971 5 308.19V38.4848C89.1664 29.7037 153.044 18.5562 188 6V340.675Z'
              }}
              clipPath={
                state.funnel.includes(tagLookup['funnel-category-retention'].id)
                  ? 'M183 334.675C148.044 322.119 84.1664 310.971 0 302.19V32.4848C84.1664 23.7037 148.044 12.5562 183 0V334.675Z'
                  : undefined
              }
              onClick={() =>
                dispatch({type: 'toggleFunnel', payload: tagLookup['funnel-category-retention'].id})
              }
            />
          </Flex>
        </Flex>
      </Box>
    </>
  );
};

export default StepFunnel;

const Tile: FC<{
  name: string;
  description?: string;
  tileHeight: number;
  borderHeight?: number;
  clipPath?: string;
  outline: {h: number; path: string};
  onClick: () => void;
}> = ({name, description, tileHeight, borderHeight, clipPath, outline, onClick}) => {
  const [isHover, setHover] = useState<boolean>(false);
  const TILE_WIDTH = 183;
  const TEXT_WIDTH = 153;
  const OUTLINE_WIDTH = 193;
  const isSelected = !!clipPath;
  return (
    <Flex
      onClick={onClick}
      flexDirection="column"
      h={tileHeight}
      w={TILE_WIDTH}
      cursor="pointer"
      position="relative"
      justifyContent="center"
      onMouseEnter={() => !isSelected && setHover(true)}
      onMouseLeave={() => setHover(false)}
      backgroundColor={isSelected ? 'lime.200' : 'transparent'}
      clipPath={isSelected ? `path('${clipPath}')` : 'none'}
      color={isSelected ? 'black' : 'textColor.100'}
    >
      <Flex
        flexDirection="column"
        gap="10px"
        p="10px"
        justifyContent="center"
        height={borderHeight ? borderHeight : 'auto'}
        borderLeft={borderHeight ? '1px solid #202126' : 'none'}
      >
        <Text
          fontSize="20px"
          fontWeight="600"
          lineHeight="20px"
          textAlign="center"
          position="relative"
          mt={6}
        >
          {name}
        </Text>
        {description && (
          <Flex flexDirection="column" alignItems="center" w={TEXT_WIDTH} pt={4} minHeight={130}>
            <Text
              fontSize="12px"
              fontWeight="700"
              lineHeight="16px"
              pb={2}
              color={isSelected ? 'black' : 'gray.30'}
            >
              Metrics Influenced
            </Text>
            <ul style={{marginLeft: 20}}>
              {description.split('\\').map((d, i) => (
                <li
                  key={i}
                  style={{
                    margin: 0,
                    padding: 0,
                    paddingBottom: 4,
                    color: isSelected ? 'black' : '#B6B7BE',
                    fontSize: '12px'
                  }}
                >
                  <Text
                    fontSize="12px"
                    fontWeight="500"
                    lineHeight="16px"
                    color={isSelected ? 'black' : '#B6B7BE'}
                  >
                    {d}
                  </Text>
                </li>
              ))}
            </ul>
          </Flex>
        )}
      </Flex>
      <Box
        position="absolute"
        left="-7px"
        className="highlight"
        display={isHover ? 'block' : 'none'}
      >
        <svg
          width={OUTLINE_WIDTH}
          height={outline.h}
          viewBox={`0 0 ${OUTLINE_WIDTH} ${outline.h}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path d={outline.path} stroke="#E2FE62" strokeWidth="2" shapeRendering="crispEdges" />
          </g>
        </svg>
      </Box>
    </Flex>
  );
};

const Background: FC = () => (
  <Flex
    h={377}
    position="absolute"
    overflow="visible"
    width="100vw"
    left="0"
    alignItems="center"
    flexDirection="column"
  >
    <svg
      width="1323"
      height="377"
      viewBox="0 0 1323 377"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2039_660)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 352.218V24.7815C114.76 53.5023 363.305 73.9999 655 73.9999C961.223 73.9999 1219.89 51.4098 1303 20.4307V356.569C1219.89 325.59 961.223 303 655 303C363.305 303 114.76 323.497 20 352.218Z"
          fill="#0F1015"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2039_660"
          x="0"
          y="0.430664"
          width="1323"
          height="376.139"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.592157 0 0 0 0 0.278431 0 0 0 0 1 0 0 0 0.3 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2039_660" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2039_660"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  </Flex>
);
