import React, {FC} from 'react';
import {VStack} from '@chakra-ui/react';
import Title from '../Title';
import ListOption from '../ListOption';
import {useCreateProgramContext} from '../CreateProgramModal';
import {CourseWaitlistDesiredUrgencyTypeEnum} from 'generated/graphql';

export const getTimeframeName = (timeframe: CourseWaitlistDesiredUrgencyTypeEnum | undefined) => {
  switch (timeframe) {
    case CourseWaitlistDesiredUrgencyTypeEnum.NEXT_3_WEEKS:
      return '3 Weeks';
    case CourseWaitlistDesiredUrgencyTypeEnum.NEXT_6_WEEKS:
      return '6 Weeks';
    case CourseWaitlistDesiredUrgencyTypeEnum.NEXT_3_MONTHS:
      return '3 Months';
    default:
      return '';
  }
};

const StepTimeframe: FC = () => {
  const {state, dispatch} = useCreateProgramContext();
  return (
    <>
      <Title
        title="What is your timeframe?"
        subtitle="Select one."
        onBack={() => dispatch({type: 'goBack'})}
      />
      <VStack spacing={4} alignItems="flex-start" w="100%">
        <ListOption
          number={1}
          name={getTimeframeName(CourseWaitlistDesiredUrgencyTypeEnum.NEXT_3_WEEKS)}
          onClick={() =>
            dispatch({
              type: 'setTimeframe',
              payload: CourseWaitlistDesiredUrgencyTypeEnum.NEXT_3_WEEKS
            })
          }
          isSelected={state.timeframe === CourseWaitlistDesiredUrgencyTypeEnum.NEXT_3_WEEKS}
        />
        <ListOption
          number={2}
          name={getTimeframeName(CourseWaitlistDesiredUrgencyTypeEnum.NEXT_6_WEEKS)}
          onClick={() =>
            dispatch({
              type: 'setTimeframe',
              payload: CourseWaitlistDesiredUrgencyTypeEnum.NEXT_6_WEEKS
            })
          }
          isSelected={state.timeframe === CourseWaitlistDesiredUrgencyTypeEnum.NEXT_6_WEEKS}
        />
        <ListOption
          number={3}
          name={getTimeframeName(CourseWaitlistDesiredUrgencyTypeEnum.NEXT_3_MONTHS)}
          onClick={() =>
            dispatch({
              type: 'setTimeframe',
              payload: CourseWaitlistDesiredUrgencyTypeEnum.NEXT_3_MONTHS
            })
          }
          isSelected={state.timeframe === CourseWaitlistDesiredUrgencyTypeEnum.NEXT_3_MONTHS}
        />
      </VStack>
    </>
  );
};

export default StepTimeframe;
