import React, {FC} from 'react';
import {Flex, Text} from '@chakra-ui/react';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

interface Props {
  number?: number;
  icon?: IconDefinition;
  name: string;
  description?: string;
  onClick: () => void;
  isSelected?: boolean;
}

const ListOption: FC<Props> = ({number, name, description, onClick, isSelected, icon}) => {
  return (
    <Flex
      cursor="pointer"
      onClick={onClick}
      w="100%"
      borderWidth={1}
      borderColor={isSelected ? 'lime.200' : 'gray.700'}
      borderRadius={8}
      p={6}
      background="rgba(15, 16, 21, 1)"
      alignItems="center"
    >
      <Flex
        w={54}
        h={50}
        mr={3}
        borderRadius={8}
        background="linear-gradient(270deg, rgba(26, 192, 153, 0.02) 0%, rgba(0, 6, 13, 0) 100%)"
        justifyContent="center"
        alignItems="center"
      >
        <Text fontWeight="700" fontSize="24px" color="lime.200" lineHeight="24px">
          {icon ? <FontAwesomeIcon icon={icon} /> : number}
        </Text>
      </Flex>
      <Flex alignItems="flex-start" flexDirection="column">
        <Text fontWeight="700" fontSize="24px" color="gray.25" lineHeight="24px">
          {name}
        </Text>
        {description && (
          <Text fontWeight="400" fontSize="14px" color="gray.50" lineHeight="14px" mt={4}>
            {description}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default ListOption;
