import {useSession} from 'context/SessionContext';
import {useMemo} from 'react';

export default function useIsManager(): boolean {
  const {data: session} = useSession();

  const isManager = useMemo(
    () => session?.user?.abilities?.editTeamEnrollments ?? false,
    [session?.user?.abilities]
  );

  return isManager;
}
